import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Image, Text } from 'rebass';
import { COUNTRY } from '../../../configs/env';
import { NeutralStringObjectI } from '../../../types/NeutralObject.type';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';

interface StoreBadgeIconsPropsI {
  partnersStoreBadgeIcons?: boolean;
  customFlexWrapper?: boolean;
}

interface BadgeIconsI {
  appleStoreLink: string;
  googleStoreLink: string;
  huaweiStoreLink: string;
}

interface IconElementsPropsI {
  badgeIcons: BadgeIconsI;
}

interface BadgeIconsContainerI {
  defaults: BadgeIconsI;
  partners: BadgeIconsI;
}

const onStoreBtnClick = (btn: string) => {
  const storeBtnClickEvents: NeutralStringObjectI = {
    playStore: 'web_play_store_button_click',
    appStore: 'web_app_store_button_click',
    huaweiStore: 'web_huawei_store_button_click',
  };

  gTagDataLayerPush({
    event: storeBtnClickEvents[btn],
    country: COUNTRY,
    pagePath: window.location.pathname,
    // timeSpentOnPage: '...' @TODO
  });

  console.log(`Triggered gTag data layer push for ${btn} - ${storeBtnClickEvents[btn]}`);
};

const IconElements: React.FC<IconElementsPropsI> = ({ badgeIcons }) => {
  return (
    <>
      <Text mr={{ _: 1, sm: 2 }}>
        <a
          onClick={() => {
            onStoreBtnClick('appStore');
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={badgeIcons.appleStoreLink}
        >
          <Image src="/img/apple-badge-new@2x.png" />
        </a>
      </Text>
      <Text mr={{ _: 1, sm: 2 }}>
        <a
          onClick={() => {
            onStoreBtnClick('playStore');
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={badgeIcons.googleStoreLink}
        >
          <Image src="/img/google-play-new@2x.png" />
        </a>
      </Text>
      <Text>
        <a
          onClick={() => {
            onStoreBtnClick('huaweiStore');
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={badgeIcons.huaweiStoreLink}
        >
          <Image src="/img/huawei-badge-new@2x.png" />
        </a>
      </Text>
    </>
  );
};

const StoreBadgeIcons: React.FC<StoreBadgeIconsPropsI> = ({ partnersStoreBadgeIcons = false, customFlexWrapper = false }) => {
  const { t } = useTranslation();

  const badgeIconsContainer: BadgeIconsContainerI = {
    defaults: {
      appleStoreLink: t('link_ios-user-app'),
      googleStoreLink: t('link_android-user-app'),
      huaweiStoreLink: t('link_huawei-user-app'),
    },
    partners: {
      appleStoreLink: t('link_ios-driver-app'),
      googleStoreLink: t('link_android-driver-app'),
      huaweiStoreLink: t('link_huawei-driver-app'),
    },
  };

  let badgeIcons = badgeIconsContainer.defaults;

  if (partnersStoreBadgeIcons) {
    badgeIcons = badgeIconsContainer.partners;
  } else {
    badgeIcons = badgeIconsContainer.defaults;
  }

  return (
    <>
      {!customFlexWrapper && (
        <Flex flexDirection="row">
          <IconElements badgeIcons={badgeIcons} />
        </Flex>
      )}
      {customFlexWrapper && <IconElements badgeIcons={badgeIcons} />}
    </>
  );
};

export default StoreBadgeIcons;
