import React from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass';
import { WebshopNewLayout } from '../../../layouts/WebshopNew.layout';
import { useRecoilValue } from 'recoil';
import './purchase-final-payslip.css';
import { bundlePaySlip, purchasePackage, webshopUser } from '../../../shared-state/main';
import { navigateTo } from '../../../components/functional/Navigate';
import { weAreRunningInBrowser } from '../../../utils/weAreRunningInBrowser';
import { gTagDataLayerPush } from '../../../utils/gTagDataLayerPush.function';
import { COUNTRY } from '../../../configs/env';
import StoreBadgeIcons from '../../../components/layout/StoreBadgeIcons/StoreBadgeIcons';

const PurchaseFinalPayslipPage = () => {
  const { t, i18n } = useTranslation();

  const bundle = useRecoilValue(bundlePaySlip);
  const user = useRecoilValue(webshopUser);
  const bundleDetails = useRecoilValue(purchasePackage);

  const LANG = i18n.language;

  const metaOgImg = `/img/meta-img/road-assistance-meta-og-2-0-new.jpeg`;

  const meta = {
    title: t('meta_RoadsideAssistanceTitle'),
    description: t('meta_RoadsideAssistanceDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  // const numberWithCommas = (x: number) => {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // };

  // React.useEffect(() => {
  //   if (bundle !== null && bundle.bundleName && bundle.bundlePrice) {
  //     // setBundleName(bundle.bundleName);
  //     // setBundlePrice(bundle.bundlePrice);
  //   }
  //   return () => {
  //     // setBundleName(null);
  //     // setBundlePrice(null);
  //     setBundle({
  //       bundleId: null,
  //       bundleName: null,
  //       bundlePrice: null,
  //     });
  //     setUser({
  //       ...user,
  //       userFirstName: null,
  //       userLastName: null,
  //       userMobileNumber: null,
  //       userEmail: null,
  //     });
  //   };
  // }, []);

  if (user === null || user.userEmail === null) {
    navigateTo(t('link_home'));
    return null;
  } else {
    React.useEffect(() => {
      if (weAreRunningInBrowser()) {
        gTagDataLayerPush({
          event: 'web_shop_payslip_requested',
          price: bundleDetails.packagePrice,
          country: COUNTRY,
          bundleKeyName: bundleDetails.bundleKeyName,
        });
      }
    }, []);

    return (
      <WebshopNewLayout meta={meta} og={og}>
        <Container style={{ padding: '60px 0', backgroundColor: 'white' }}>
          <div style={{}}>
            <img src="/img/success-payment.png" />
            <Text style={{ margin: '50px 0' }}>
              {t('successfullPayslipEmail')} {user.userEmail}.
              <br />
              <br /> {t('successfullPayslipPhoneNumberPartOne')} {user.userMobileNumber}
              {t('successfullPayslipPhoneNumberPartTwo')}
              <br /> {t('successfullPayslipPhoneNumberPartThree')}
              <br />
              <br /> {t('successfullPurchaseThankYouText')}
            </Text>
            <StoreBadgeIcons />
          </div>
          <div>
            <div style={{ maxWidth: '718px', marginTop: '50px' }}>
              <table role="presentation" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td
                      className="mobile-table"
                      style={{
                        border: '1px solid black',
                        direction: 'ltr',
                        fontSize: '0px',
                        padding: '20px 0',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        className="m_1091965345581889388mj-column-per-50 first-table-element"
                        style={{
                          fontSize: '0px',
                          textAlign: 'left',
                          direction: 'ltr',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          width: '50%',
                        }}
                      >
                        <table role="presentation" style={{ verticalAlign: 'top' }} width="100%">
                          <tbody>
                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontSize: '0px',
                                  padding: '30px 15px 0px 15px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <table
                                  width="100%"
                                  style={{
                                    color: '#000000',
                                    fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    tableLayout: 'auto',
                                    width: '100%',
                                    border: 'none',
                                  }}
                                >
                                  <tbody>
                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td style={{ padding: '10px 30px 0px 5px' }}>Uplatilac</td>
                                    </tr>

                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td
                                        style={{
                                          padding: '10px 30px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        {user.userFirstName} {user.userLastName}
                                      </td>
                                    </tr>
                                    <tr style={{ fontSize: '10px' }}>
                                      <td style={{ padding: '0px 30px 0px 5px' }}>Svrha uplate</td>
                                    </tr>
                                    <tr
                                      style={{
                                        textAlign: 'left',
                                        fontSize: '10px',
                                        padding: '5px 0px',
                                      }}
                                    >
                                      <td
                                        style={{
                                          padding: '10px 30px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        {bundle.bundleName}
                                      </td>
                                    </tr>
                                    <tr style={{ fontSize: '10px' }}>
                                      <td style={{ padding: '0px 30px 0px 5px' }}>Primalac</td>
                                    </tr>
                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td
                                        style={{
                                          padding: '10px 30px 20px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        KOV Technology d.o.o. Živojina Žujovića 14, Beograd
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td
                                className="m_1091965345581889388mobile-display-none"
                                style={{
                                  fontSize: '0px',
                                  padding: '35px 15px 0px 15px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <p
                                  style={{
                                    borderTop: 'solid 1px #000000',
                                    fontSize: '1px',
                                    margin: '0px auto',
                                    width: '100%',
                                  }}
                                ></p>
                              </td>
                            </tr>

                            <tr>
                              <td
                                align="center"
                                className="m_1091965345581889388mobile-display-none"
                                style={{
                                  fontSize: '0px',
                                  padding: '5px 0px 0px 0px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <div
                                  style={{
                                    fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                    fontSize: '10px',
                                    lineHeight: '1',
                                    textAlign: 'center',
                                    color: '#16191e',
                                  }}
                                >
                                  Pečat i potpis uplatioca
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div
                        className="m_1091965345581889388mj-column-per-50 second-table-element"
                        style={{
                          fontSize: '0px',
                          textAlign: 'left',
                          direction: 'ltr',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          width: '50%',
                        }}
                      >
                        <table role="presentation" style={{ verticalAlign: 'top' }} width="100%">
                          <tbody>
                            <tr>
                              <td
                                align="center"
                                className="m_1091965345581889388mobile-display-none"
                                style={{
                                  fontSize: '0px',
                                  padding: '0px 0px 0px 35px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <div
                                  className="payslip-title"
                                  style={{
                                    fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    lineHeight: '1',
                                    textAlign: 'center',
                                    color: '#16191e',
                                  }}
                                >
                                  NALOG ZA UPLATU
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontSize: '0px',
                                  padding: '10px 25px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <table
                                  width="100%"
                                  style={{
                                    color: '#000000',
                                    fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    tableLayout: 'auto',
                                    width: '100%',
                                    border: 'none',
                                  }}
                                >
                                  <tbody>
                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td style={{ padding: '10px 0px 0px 5px' }}>Šifra plaćanja</td>
                                      <td></td>
                                      <td style={{ padding: '10px 0px 0px 5px' }}>Valuta</td>
                                      <td></td>
                                      <td style={{ padding: '10px 0px 0px 5px' }}>Iznos</td>
                                    </tr>

                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td
                                        style={{
                                          padding: '10px 0px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        189
                                      </td>
                                      <td style={{ padding: '5px' }}></td>
                                      <td
                                        style={{
                                          padding: '10px 0px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        RSD
                                      </td>
                                      <td style={{ padding: '5px' }}></td>
                                      <td
                                        style={{
                                          padding: '10px 0px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        {bundle.bundlePrice},00
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontSize: '0px',
                                  padding: '10px 25px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <table
                                  width="100%"
                                  style={{
                                    color: '#000000',
                                    fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    tableLayout: 'auto',
                                    width: '100%',
                                    border: 'none',
                                  }}
                                >
                                  <tbody>
                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td>Račun primaoca</td>
                                    </tr>
                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td
                                        style={{
                                          padding: '10px 0px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        170-30029892000-73
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontSize: '0px',
                                  padding: '10px 25px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                <table
                                  width="100%"
                                  style={{
                                    color: '#000000',
                                    fontFamily: 'Ubuntu,Helvetica,Arial,sans-serif',
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                    tableLayout: 'auto',
                                    width: '100%',
                                    border: 'none',
                                  }}
                                >
                                  <tbody>
                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td>Broj modela</td>
                                      <td></td>
                                      <td>Poziv na broj (odobrenje)</td>
                                    </tr>

                                    <tr style={{ textAlign: 'left', fontSize: '10px' }}>
                                      <td
                                        style={{
                                          padding: '10px 0px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      ></td>
                                      <td style={{ padding: '5px' }}></td>
                                      <td
                                        style={{
                                          padding: '10px 0px 10px 5px',
                                          border: '1px solid black',
                                        }}
                                      >
                                        {user.realBundleId}/{new Date().getFullYear()}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Container>
      </WebshopNewLayout>
    );
  }
};

export default PurchaseFinalPayslipPage;
